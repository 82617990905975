import React from "react"
import { usePageSetup } from "@components/usePageSetup"
import { Layout } from "@components/Layout"
import { Seo } from "@components/Seo"
import { Hero } from "@components/Hero"
import { Video, AboutJK } from "@components/Secondary"
import { splitText } from "@utils"

const ThirteenQuestionsPage = ({ data }) => {
  const { themeColor } = usePageSetup({ themeColor: "bg-white" })

  return (
    <Layout>
      <Seo title="13 Questions" description="" />
      {/* HERO */}

      <section className={themeColor}>
        <Hero>
          <Hero.Title>
            <h1
              data-aos="stagger"
              dangerouslySetInnerHTML={{
                __html: splitText({
                  "text-gray-darkest": "13 questions with",
                  break: "",
                  "text-teal": `Martha Marchesi`,
                }),
              }}
            />
          </Hero.Title>
          <Hero.Message overide={true}>
            <div className="w-full grid-cols-10 lg:grid gap-x-4 mt-11">
              <div className="col-span-6 col-start-4">
                <div data-aos="fade-up">
                  <p>
                    <span className="font-bold">Get the inside scoop</span> straight from the woman in charge. See how
                    CEO Martha Marchesi answered these 13 questions!
                  </p>
                </div>
              </div>
            </div>
          </Hero.Message>
        </Hero>
      </section>

      <main>
        <Video.Wrapper>
          <Video id="384038832" title="13 questions with Martha Marchesi" />
        </Video.Wrapper>
        <AboutJK />
      </main>
    </Layout>
  )
}

export default ThirteenQuestionsPage
